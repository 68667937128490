export const roles = {
  administrators: 'admin',
  sales: 'salesman',
  clients: 'customer',
  countryadmins: 'country',
  experts: 'expert',
};

export const userGroups = [roles.administrators, roles.countryadmins, roles.experts, roles.sales, roles.clients];
export const countryadminGivingRoles = userGroups.filter((role) => role !== roles.administrators);
