import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { fromEvent } from 'file-selector';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { IconButton, Typography } from 'ui';
import { ReactComponent as DownloadIcon } from 'ui/icons/actions/Download.svg';
import { FormattedMessage, normalizeName } from 'utils';

const useStyles = makeStyles((theme) => ({
  dropbox: {
    flexGrow: 1,
    padding: 15,
    border: '2px solid black',
    position: 'relative',
    background: '#fff',
    zIndex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before, &::after': {
      position: 'absolute',
      background: '#FFF',
      zIndex: -1,
      transition: '1s',
      content: "''",
    },
    '&::before': {
      width: 'calc(100% + 4px)',
      height: 'calc(100% - 30px)',
    },
    '&::after': {
      width: 'calc(100% - 30px)',
      height: 'calc(100% + 4px)',
    },
  },
  dropboxActive: {
    '&::before': {
      height: 0,
    },
    '&::after': {
      width: 0,
    },
  },
  fileName: {
    color: theme.palette.primary.main,
  },
  dropboxFilled: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  lineInfo: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}));

export function Map({ file, setFile, index, onAddDocument }) {
  const classes = useStyles();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles?.[0]) {
        return;
      }
      const acceptedFile = { ...acceptedFiles?.[0] };
      acceptedFile.path = normalizeName(acceptedFile.path);
      const reader = new FileReader();
      reader.onload = (event) => {
        const key = file.key + acceptedFile.path;
        const result = event.target.result;
        if (result.startsWith('data:image')) {
          const image = new Image();
          image.src = result;
          image.onload = () => {
            const imgHeight = image.height;
            const imgWidth = image.width;
            //TODO: clean image from memory?
            setFile({ ...acceptedFile, img: result, imgHeight, imgWidth, key }, index);
            onAddDocument({
              file: { ...file, ...acceptedFile, content: result, key }, // { tag, title, path, content }
              tag: file.tag ?? '',
            });
          };
        } else {
          if (result.startsWith('data:application/pdf')) {
            onAddDocument({
              file: { ...file, ...acceptedFile, content: event.target.result, key }, // { tag, title, path, content }
              tag: file.tag ?? '',
            });
            setFile(
              {
                ...acceptedFile,
                img: 'We get the result of onAddDocument',
                imgHeight: 2048,
                imgWidth: 1170,
                key,
              },
              index
            );
            // We check if its a pdf.
          }
        }
      };
      reader.readAsDataURL(acceptedFiles?.[0]);
    },
    [index, setFile]
  );

  const getFilesFromEvent = async (event) => {
    const fileList = await fromEvent(event);
    const file = fileList[0];
    if (!file) {
      return [];
    }
    const extraData = event.dataTransfer?.getData('text/plain');
    if (file.path === undefined) {
      file.path = extraData || file.name;
    }
    return [file];
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, getFilesFromEvent, multiple: false });
  return (
    <div
      className={
        isDragActive
          ? clsx(classes.dropbox, classes.dropboxActive)
          : file && file.name
          ? clsx(classes.dropbox, classes.dropboxFilled)
          : classes.dropbox
      }
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <div className={classes.lineInfo}>
        <Typography variant="body1">
          <FormattedMessage id="quoteInside.levelNumber" values={{ index: file.ground }} />
        </Typography>

        {file.file && file.file.path ? (
          <>
            <Typography variant="bodyXSsemibold" component="span" className={classes.fileName}>
              {file.file.path}
            </Typography>
          </>
        ) : (
          <>
            <IconButton>
              <DownloadIcon />
            </IconButton>
            <FormattedMessage id="quoteInside.fileFormat" />
          </>
        )}
      </div>
    </div>
  );
}
