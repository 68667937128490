import {
  Avatar,
  Button,
  Chip,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@material-ui/core';
import { groupLabelByName } from 'administrators/administration/useUserGroupFilter';
import { useAuthContext } from 'auth';
import { format } from 'date-fns';
import { API } from 'network/useRenaultApi';
import { parse, stringify } from 'query-string';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import { Alert, Nav, NavItem, NavLink } from 'reactstrap';
import { roles } from 'roles';
import { blueColor, greyColor, orangeColor } from 'theme';
import { TertiaryButton } from 'ui/buttons/tertiaryButton';
import { ReactComponent as SearchIcon } from 'ui/icons/actions/Search.svg';
import { FormattedMessage, useDebounce, useIntl } from 'utils';
import { getComparatorFn, stableSort } from 'utils/sortingFn';
import * as XLSX from 'xlsx';
import { ErrorAlert, LoadingIndicator, LoadMore } from '../common';
import { useMyClients, useUsers } from '../users';

const color = [orangeColor, blueColor, greyColor];

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '30px',
    height: '30px',
  },
  topActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: theme.spacing(2),
  },
}));

function descendingComparator(a, b, orderBy) {
  switch (orderBy) {
    case 'creationDate':
      return (b.createdAt ?? b.linkedAt) < (a.createdAt ?? a.linkedAt)
        ? -1
        : (b.createdAt ?? b.linkedAt) > (a.createdAt ?? a.linkedAt)
        ? 1
        : 0;
    case 'customer':
      return (b.companyName ?? b.name ?? '').localeCompare(a.companyName ?? a.name);
    case 'role':
      return b.role < a.role ? -1 : b.role > a.role ? 1 : 0;
    default:
      return 0;
  }
}

const getComparator = getComparatorFn(descendingComparator);

const ClientArray = React.memo(({ userList, match, order, orderBy, onRequestSort }) => {
  const { groups } = useAuthContext();
  const intl = useIntl();
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    userList &&
    userList.length > 0 && (
      <Paper elevation={1}>
        <TableContainer>
          <Table aria-label={'Estimations'}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell sortDirection={orderBy === 'creationDate' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'creationDate'}
                    direction={orderBy === 'creationDate' ? order : 'asc'}
                    onClick={createSortHandler('creationDate')}
                  >
                    <FormattedMessage id="userList.creationDate" defaultMessage="Date de création" />
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'customer' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'customer'}
                    direction={orderBy === 'customer' ? order : 'asc'}
                    onClick={createSortHandler('customer')}
                  >
                    <FormattedMessage id="userList.customer" defaultMessage="Customer" />
                  </TableSortLabel>
                </TableCell>
                {(groups[roles.countryadmins] || groups[roles.administrators]) && (
                  <TableCell sortDirection={orderBy === 'role' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'role'}
                      direction={orderBy === 'role' ? order : 'asc'}
                      onClick={createSortHandler('role')}
                    >
                      <FormattedMessage id="userList.role" defaultMessage="Role" />
                    </TableSortLabel>
                  </TableCell>
                )}
                {(groups[roles.countryadmins] || groups[roles.administrators]) && (
                  <TableCell>
                    <FormattedMessage id="userList.country" defaultMessage="Country" />
                  </TableCell>
                )}
                <TableCell>
                  <FormattedMessage id="userList.estimation" defaultMessage="Estimation" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="userList.detail" defaultMessage="Détail" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.map((user, index) => (
                <TableRow key={user.id}>
                  <TableCell component="th" scope="row">
                    <Avatar style={{ backgroundColor: color[index % color.length][60] }} className={classes.avatar}>
                      {user && user.email ? user.email.charAt(0) : ''}
                    </Avatar>
                  </TableCell>
                  <TableCell>{intl.formatDate(user.createdAt || user.linkedAt)} </TableCell>
                  <TableCell>
                    {user.companyName ? `${user.companyName} - ` : ''}
                    {user.name}
                  </TableCell>
                  {(groups[roles.countryadmins] || groups[roles.administrators]) && (
                    <TableCell>
                      <FormattedMessage {...(groupLabelByName[user.role] ?? { defaultMessage: user.role })} />
                    </TableCell>
                  )}
                  {(groups[roles.countryadmins] || groups[roles.administrators]) && (
                    <TableCell>{user.profile?.countryMap?.join(', ')}</TableCell>
                  )}
                  <TableCell>
                    <Link to={{ pathname: '/quotes/new', state: { forUser: user.id } }}>
                      <Chip
                        style={{
                          fontWeight: 600,
                          cursor: 'pointer',
                        }}
                        label={
                          <FormattedMessage id="clientsCreateQuote" defaultMessage="Create estimation for client" />
                        }
                      />
                    </Link>{' '}
                  </TableCell>
                  <TableCell>
                    <Link to={`${match.url}/${user.id}`}>
                      <Chip
                        style={{
                          fontWeight: 600,
                          cursor: 'pointer',
                        }}
                        label={<FormattedMessage id="clientsManageUser" defaultMessage="Manage user" />}
                      />
                    </Link>{' '}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  );
});

// Default export because admin stuff is lazy-imported
export default function ClientList({ match, location }) {
  const { me, groups, accessToken } = useAuthContext();
  const intl = useIntl();
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const searchValueDebounced = useDebounce(searchValue, 250);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('creationDate');

  const { list = 'related', ...search } = useMemo(() => parse(location.search), [location.search]);
  const myClients = useMyClients();
  const allUsers = useUsers();
  const countries = me.profile?.countryMap ?? ['FR'];
  const users = list === 'related' ? myClients : allUsers;

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const userList = useMemo(() => {
    if (!users.value || !users.value.length) {
      return [];
    }
    const preFilteredUsers =
      list === 'related'
        ? users.value
        : groups[roles.countryadmins]
        ? (users.value ?? []).filter((u) => countries.some((c) => u.profile?.countryMap.includes(c)))
        : users.value;
    const sortedUsers = stableSort(preFilteredUsers, getComparator(order, orderBy));
    if (!searchValueDebounced) {
      return sortedUsers;
    }
    const searchReg = new RegExp(searchValueDebounced, 'i');
    return sortedUsers.filter(
      (user) =>
        searchReg.test(user.name) ||
        searchReg.test(user.email) ||
        searchReg.test(user.id) ||
        searchReg.test(user.comment) ||
        searchReg.test(user.companyAddress) ||
        searchReg.test(user.companyName) ||
        searchReg.test(user.linkedAt)
    );
  }, [countries, groups[roles.countryadmins], list, users.value, searchValueDebounced, order, orderBy]);

  const exportUsers = useCallback(async () => {
    const users = await API.get('administration/export/users', {}, accessToken);
    const parsedusers = users.map((user) => ({
      'Client ID': user.id,
      Name: user.name,
      Email: user.email,
      Role: user.role,
      Country: user.country,
      'Company name': user.companyName,
      'Company Address': user.companyAddress,
      Profile: JSON.stringify(user.profile ?? {}),
      'Created at': new Date(user.createdAt),
    }));
    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(parsedusers, {
      header: [
        'Client ID',
        'Name',
        'Email',
        'Role',
        'Country',
        'Company name',
        'Company Address',
        'Profile',
        'Created at',
      ],
    });
    XLSX.utils.book_append_sheet(book, sheet, 'All users');
    XLSX.writeFile(book, `extract_elexent_${format(new Date(), 'yyyy-MM-dd-HH-mm')}.xlsx`);
  }, [accessToken]);

  if (users.error) {
    return <ErrorAlert error={users.error} />;
  }

  if (users.loading) {
    return <LoadingIndicator />;
  }

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            tag={RouterNavLink}
            to={{ ...location, search: stringify(search) }}
            active={list === 'related'}
            activeClassName=""
          >
            <FormattedMessage id="clientsRelatedLink" defaultMessage="My Clients" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={RouterNavLink}
            to={{ ...location, search: stringify({ ...search, list: 'all' }) }}
            active={list === 'all'}
            activeClassName=""
          >
            <FormattedMessage id="clientsAllLink" defaultMessage="All Clients" />
          </NavLink>
        </NavItem>
      </Nav>
      {userList && userList.length === 0 && list === 'related' && (
        <Alert color="info" className="my-2">
          <FormattedMessage
            id="clientsNoRelated"
            defaultMessage='You do not have any related clients or users. Click "All Clients" above or "Onboard new client" below to access a client.'
          />
        </Alert>
      )}
      <TextField
        placeholder={intl.formatMessage({ id: 'clientList.searchPlaceholder' })}
        fullWidth
        value={searchValue}
        margin="normal"
        style={{ flexGrow: 1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          classes: {
            underline: classes.searchInput,
          },
        }}
        onChange={handleInputChange}
        variant="standard"
      />
      <div className={classes.topActions}>
        <Button component={Link} to={`${match.path}/new`} variant="outlined">
          <FormattedMessage id="clientsCreate" defaultMessage="Onboard new client" />
        </Button>
        {groups[roles.administrators] && (
          <TertiaryButton onClick={exportUsers}>
            <FormattedMessage id="clientsExtract" defaultMessage="Exporter la liste des utilisateurs (csv)" />
          </TertiaryButton>
        )}
      </div>
      <ClientArray
        userList={userList}
        match={match}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
      {users.loadNext && (
        <Button onClick={() => users.loadNext()} variant="outlined">
          <LoadMore />
        </Button>
      )}
    </>
  );
}
