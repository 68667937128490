import { useAuthContext } from 'auth';
import { CONTINUE_TOKEN_HEADER } from 'data-transfer-schemas';
import { API } from 'network/useRenaultApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { merge } from '../common';

export function useApi(path) {
  const [value, setValue] = useState();
  // const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uIjoiYjg4ZGEyNWUtMWUxNy00NjVhLWExODktZjQ2Nzk4ZGVmMTZlIiwidXVpZCI6ImMyY2NkM2U1LTZjNjEtNGI2MC1iNDJlLTI0M2E1ZWNkZjcwMSIsImlhdCI6MTY2MTI2NzI0NTE1MSwiZXhwIjoxNjYxMjY3MjQ4NzUxLCJjb3VudHJ5TWFwIjpbIkZSIiwiRVMiLCJERSJdLCJsb2NhbGUiOiJmcl9GUiIsIlNhbGVzIjp7IlZOIjp0cnVlLCJWTyI6dHJ1ZSwiQjJCIjp0cnVlLCJSVlAiOnRydWV9LCJkZWFsZXIiOnsic2FsZXNtYW4iOnsiZmlyc3ROYW1lIjoiRGEiLCJsYXN0TmFtZSI6IlplIiwiZW1haWwiOiJkYXZpZC54b3hAZ21haWwuY29tIiwiZW50aXR5IjoiMzBmOTM5NCIsImlwbiI6IjdBMUhNQTJVTiJ9LCJkZWFsZXJOYW1lIjoiRUxUTzIiLCJhZGRyZXNzIjp7InppcENvZGUiOiI3NTExNiIsImNvdW50cnkiOiJGcmFuY2UiLCJhZGRpdGlvbmFsSW5mb3JtYXRpb24iOiIiLCJjaXR5IjoiUGFyaXMiLCJhZGRyZXNzVHlwZSI6Ik1BSU4iLCJhZGRyZXNzTGluZSI6InRlc3QiLCJ0ZXJyaXRvcmlhbERpdmlzaW9uIjoiIn0sImRlYWxlcklkIjoiMzBmOTM5NCIsInJyZkRlYWxlcklkIjoiMzBmOTM5NCJ9LCJ0eXBlIjoiYWRtaW4iLCJmdWxsbmFtZSI6IkRBIFpFIiwiaXBuIjoiN0ExSE1BMlVOIiwiaXNzIjoiZWx0byIsInVpZCI6IjdBMUhNQTJVTiJ9.qK8P5yTtRbfkESZswdbd8gMfUZdQ3ZkBdA9CsTcc99A'
  const { accessToken } = useAuthContext();
  const load = useCallback(
    function loadData() {
      return path ? API.get(path, {}, accessToken) : Promise.resolve(null);
    },
    [accessToken, path]
  );

  const reload = useCallback(
    function reload() {
      load().then(setValue, (e) => {
        if (e?.errors) {
          setValue(new Error(e.errors));
        } else {
          setValue(e);
        }
      });
    },
    [load, path]
  );

  const replace = useCallback(function replace(newValue) {
    setValue((oldValue) => merge(oldValue, newValue)); // setValue(newValue)
  }, []);

  useEffect(reload, [reload]);

  return useMemo(
    () => ({
      loading: typeof value === 'undefined',
      value: value instanceof Error ? undefined : value,
      error: value instanceof Error ? value : undefined,
      reload,
      replace,
    }),
    [value, reload, replace]
  );
}

export function useListApi(path, deps) {
  const [value, setValue] = useState();
  const [receivedContinueToken, setReceivedContinueToken] = useState();
  const { accessToken } = useAuthContext();

  const load = useCallback(
    function loadData(continueToken) {
      if (!path) {
        return Promise.resolve([]);
      }

      return API.get(path, { response: true }, accessToken, null, continueToken).then((response) => {
        setReceivedContinueToken(response.headers[CONTINUE_TOKEN_HEADER]);
        if (response.errors) {
          throw new Error(response.errors);
        }
        return response.data;
      });
    },
    [accessToken, path]
  );

  const reload = useCallback(
    function reload() {
      load()
        .then(setValue, (e) => {
          setValue(e);
        })
        .catch((e) => setValue(e));
    },
    [load, path]
  );
  const loadNext = useCallback(
    function loadNext() {
      load(receivedContinueToken).then(
        (next) => setValue([...value, ...next]),
        (e) => {
          setValue(e);
        }
      );
    },
    [load, receivedContinueToken, value, path]
  );

  const replace = useCallback(function replace(newValue) {
    setValue(newValue); // setValue(newValue)
  }, []);

  useEffect(reload, [reload]);

  return useMemo(
    () => ({
      loading: typeof value === 'undefined',
      value: value instanceof Error ? undefined : value,
      error: value instanceof Error ? value : undefined,
      loadNext: receivedContinueToken && loadNext,
      reload,
      replace,
    }),
    [value, receivedContinueToken, reload, replace]
  );
}
