import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';

export const QUOTE_FEATURE_KEY = 'quote';

export const initialQuoteState = {
  chargerCtx: {
    step: 0,
  },
  inside: {
    isPositioning: false,
    wantInstall: false,
  },
};

export const setupExistingInside = createAction('quote/setupExistingInside');

export const quoteSlice = createSlice({
  name: QUOTE_FEATURE_KEY,
  initialState: initialQuoteState,
  reducers: {
    setChargetCtxStep: (state, action) => {
      state.chargerCtx.step = action.payload;
    },
    setIsPositioning: (state, action) => {
      state.inside.isPositioning = action.payload;
    },
    setWantInstall: (state, action) => {
      state.inside.wantInstall = action.payload;
    },
    reset: (state) => {
      state.chargerCtx.step = 0;
      state.inside.isPositioning = false;
      state.inside.wantInstall = false;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(setupExistingInside, (state) => {
      state.inside.isPositioning = true;
      state.inside.wantInstall = true;
    }),
});

export const quoteReducer = quoteSlice.reducer;

export const quoteActions = quoteSlice.actions;

const getQuoteState = (s) => s[QUOTE_FEATURE_KEY];

export const selectChargerCtxStep = createSelector(getQuoteState, (s) => s.chargerCtx.step);
export const selectIsPositioning = createSelector(getQuoteState, (s) => s.inside.isPositioning);
export const selectWantInstall = createSelector(getQuoteState, (s) => s.inside.wantInstall);
