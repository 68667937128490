import { makeStyles } from '@material-ui/core';
import { useAuthContext } from 'auth.js';
import { ControlPosition, GMapCustomControl, MapType } from 'common/gmap-custom-control.js';
import { googleMaps } from 'common/util.js';
import { endpoint } from '../constants';
import { useAppSelector } from 'data-state/hooks.js';
import { selectInstallationFiles, selectIsOutside, selectSelectedFile } from 'data-state/index.js';
import GoogleMapReact from 'google-map-react';
import { API } from 'network/useRenaultApi';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { roles } from 'roles.js';
import { primaryColor, secondaryColor } from 'theme';
import connectLocSrc from 'ui/icons/illustrations/ConnectLocMap.svg';
import tabdivLocSrc from 'ui/icons/illustrations/TabdivLocMap.svg';
import wallboxLocSrc from 'ui/icons/illustrations/WallboxLocMap.svg';
import wallboxLocWhiteSrc from 'ui/icons/illustrations/WallboxLocWhiteMap.svg';
import { FormattedMessage, useIntl } from 'utils';
import { LayerController } from './LayerController.js';
import {
  areLatLngEquals,
  areLatLngEqualsInside,
  arrayToLatLngFast,
  ChargerPlacementCtx,
  chargingSolutionCodes,
  computeGPSLatLng,
  computeGPSPosition,
  computePixelPosition,
  convertInsideRouteToPayload,
  convertParkingBBtoPixelPos,
  convertRouteToPayload,
  convertStateToChange,
  createMarker,
  DEFAULT_GROUND,
  getPopupClass,
  isChargeOrange,
  latLngToArray,
  markerImage,
  pixelToLatlng,
  rawPdcToInternalInsidePdc,
  rawPdcToInternalPdc,
  ROUTE_ORIGIN,
  setPlacesDraggable,
  updateMarker,
} from './mapUtils';
import { PlaceController } from './PlaceController.js';
import {
  RoutingController,
  ROUTING_COLORS,
  ROUTING_MODE_AERIAL,
  ROUTING_MODE_BURIED,
  ROUTING_MODE_EXISTING_BURIED,
} from './RoutingController.js';
import ValidateController from './ValidateController.js';

const useStyles = makeStyles((theme) => ({
  contextMenu: {
    // position: 'absolute',
    backgroundColor: '#FFFFFF',
    listStyle: 'none',
    paddingBlock: theme.spacing(1),
    paddingInline: 0,
    boxShadow: '0px 4px 8px 0px rgb(0 0 0 / 20%)',
    '& li': {
      paddingInline: theme.spacing(1),
      paddingBlock: theme.spacing(0.5),
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,.03)',
      },
    },
  },
  /* The popup bubble styling. */
  popupBubble: {
    /* Position the bubble centred-above its parent. */
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(-50%, -100%)',
    /* Style the bubble. */
    // backgroundColor: 'white',
    // padding: 5,
    // borderRadius: 5,
    fontFamily: '"Graphie", "sans-serif"',
    fontSize: 15,
    overflowY: 'auto',
    // maxHeight: 60,
  },

  /* The parent of the bubble. A zero-height div at the top of the tip. */
  popupBubbleAnchor: {
    /* Position the div a fixed distance above the tip. */
    position: 'absolute',
    width: '100%',
    bottom: 8,
    left: 0,
    '&::after': {
      content: '',
      position: 'absolute',
      top: 0,
      left: 0,
      /* Center the tip horizontally. */
      transform: 'translate(-50%, 0)',
      /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
      width: 0,
      height: 0,
      /* The tip is 8px high, and 12px wide. */
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: '8px solid white',
    },
  },

  /* JavaScript will position this div at the bottom of the popup tip. */
  popupContainer: {
    cursor: 'auto',
    height: 0,
    position: 'absolute',
    /* The max width of the info window. */
    width: 200,
  },
  itemDanger: {
    color: '#ff443b',
    '&:hover': {
      backgroundColor: 'rgba(255,0,0,.035) !important',
    },
  },
  markerLabel: {
    textShadow: '1px 1px 0px #000000, -1px 1px 0px #000000, -1px -1px 0px #000000, 1px -1px 0px #000000',
  },
}));

function createMapOptions(isOutside) {
  return {
    mapTypeControl: isOutside,
    mapTypeControlOptions: {
      position: ControlPosition.TOP_LEFT,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: ControlPosition.LEFT_CENTER,
    },
    rotateControl: false,
    streetViewControl: false,
    tilt: 0,
    mapTypeId: isOutside ? MapType.HYBRID : 'empty',
  };
}

class EmptyMapType {
  tileSize;
  maxZoom = 21;
  name = 'Empty';
  alt = 'Empty Map Type';
  constructor(tileSize) {
    this.tileSize = tileSize;
  }
  getTile(coord, zoom, ownerDocument) {
    const div = ownerDocument.createElement('div');
    div.style.width = this.tileSize.width + 'px';
    div.style.height = this.tileSize.height + 'px';
    div.style.backgroundColor = '#E5E3DF';
    return div;
  }
  releaseTile(tile) {}
}

const imgConnect = document?.createElement('img');
const imgTabdiv = document?.createElement('img');
const imgCharger = document?.createElement('img');
if (imgConnect) {
  imgConnect.src = connectLocSrc;
}
if (imgTabdiv) {
  imgTabdiv.src = tabdivLocSrc;
}
if (imgCharger) {
  imgCharger.src = wallboxLocSrc;
}

function HomeMap(props, ref) {
  const classes = useStyles();
  const intl = useIntl();
  const selectedParking = useAppSelector(selectSelectedFile);
  const parkingImages = useAppSelector(selectInstallationFiles);
  const isOutside = useAppSelector(selectIsOutside);
  const { selection: selectionObj, step, updateChargerCtx } = useContext(ChargerPlacementCtx);
  const { groups, accessToken } = useAuthContext();

  const computePayloadRef = useRef();
  const customControlRef = useRef();
  const popupRef = useRef();
  const stepRef = useRef(step);
  const skipEval = useRef(true);
  const parkingBBajusted = useRef();
  const contextMenu = useRef();
  const mapRef = useRef();

  const [parkingBounds, setParkingBounds] = useState();
  const [{ map, maps }, setMapObj] = useState({ map: undefined, maps: undefined });
  const [drawingManager, setDrawingManager] = useState();
  const [routingMode, setRoutingMode] = useState();
  const [contextMenuMarker, setContextMenuMarker] = useState();
  const [contextMenuRoute, setRouteContextMenu] = useState();
  const [isReplacing, setIsReplacing] = useState(false);
  const selectionCode = selectionObj?.codeProduct;
  const { onChange, value: quote, computeInitialQuote } = props;
  const [zoom, setZoom] = useState(isOutside ? googleMaps.defaultState.zoom : googleMaps.defaultStateInside.zoom);
  const [toPlace, setToPlace] = useState('');
  const [places, setPlaces] = useState({});
  const [routes, setRoutes] = useState([]);
  const [parkingBB, setParkingBB] = useState();
  const [afterInit, setAfterInit] = useState(false);

  useImperativeHandle(ref, () => ({
    getPayload: () => computePayloadRef.current?.(),
  }));

  const center = useMemo(() => {
    if (places.connection && isOutside) {
      return places.connection.marker.getPosition().toJSON();
    }
    if (props.value.center) {
      const [lng, lat] = props.value.center;
      return { lat, lng };
    }
    return googleMaps.defaultState.center;
  }, [props.value?.center?.[0], props.value?.center?.[1], places.connection]);
  const divisionalMarkerInfo = {
    label: intl.formatMessage({ id: `quoteMarkerDivisionalBoard`, defaultMessage: 'divisional board' }),
    icon: tabdivLocSrc,
    code: 'divisional',
  };
  const connectionMarkerInfo = {
    label: intl.formatMessage({ id: `quoteMarkerConnectionPoint`, defaultMessage: 'connection point' }),
    icon: connectLocSrc,
    code: 'connection',
  };
  const chargerMarkerInfo = useMemo(() => {
    const label = intl.formatMessage({
      id: 'chargingSolution' + selectionObj?.codeProduct,
      defaultMessage: selectionObj?.codeProduct,
    });
    return {
      label,
      code: selectionCode,
      icon: isChargeOrange(selectionObj) ? wallboxLocSrc : wallboxLocWhiteSrc,
    };
  }, [selectionCode]);

  const placeToMarker = useMemo(() => {
    return {
      divisional: divisionalMarkerInfo,
      connection: connectionMarkerInfo,
      charging: chargerMarkerInfo,
    };
  }, [chargerMarkerInfo]);

  const refreshPlaces = useCallback(() => {
    setPlaces((places) => ({ ...places })); //trigger useEffect depending on places
  }, []);

  /**
   * prevent changing charging position when step > 0
   */
  useEffect(() => {
    if (step === 0) {
      setPlacesDraggable(places, true);
    } else {
      setPlacesDraggable(places, false);
    }
  }, [step, places]);

  /**
   * Display a parking space bound as an editable rectangle
   */
  useEffect(() => {
    if (!parkingBB || !map || !maps || !parkingImages || !parkingBounds || !selectedParking || step !== 0) {
      return;
    }
    const ground = selectedParking.ground;
    if (!parkingBB[ground]) {
      return;
    }
    const { xmax: east, xmin: west, ymax: south, ymin: north } = parkingBB[ground];
    const parking = new maps.Rectangle({
      map,
      bounds: {
        north: computeGPSLatLng(
          parkingBounds,
          ground,
          north,
          parkingImages,
          maps.geometry.spherical.interpolate,
          'lat'
        ),
        south: computeGPSLatLng(
          parkingBounds,
          ground,
          south,
          parkingImages,
          maps.geometry.spherical.interpolate,
          'lat'
        ),
        east: computeGPSLatLng(parkingBounds, ground, east, parkingImages, maps.geometry.spherical.interpolate, 'lng'),
        west: computeGPSLatLng(parkingBounds, ground, west, parkingImages, maps.geometry.spherical.interpolate, 'lng'),
      },
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      draggable: true,
      editable: true,
      geodesic: true,
    });
    parking.addListener('bounds_changed', () => {
      parkingBBajusted.current = { ...parkingBBajusted.current, [ground]: parking.getBounds().toJSON() };
    });
    return () => {
      parking.setMap(null);
    };
  }, [map, maps, parkingBounds, parkingImages, parkingBB, selectedParking?.ground, step]);

  /**
   * Handle Drag'n'drop logic (for placing charging point) and ContextMenu marker delete
   */
  const onDrop = (e) => {
    e.preventDefault();
    const event = new MouseEvent('click', {
      ...e,
      view: window,
      bubbles: true,
      cancelable: true,
    });
    if (
      toPlace === '' ||
      !map ||
      (toPlace === 'divisional' && !!places.divisional) ||
      (toPlace === 'connection' && !!places.connection)
    ) {
      return;
    }
    const mapDiv = map.getDiv().children[0];
    const mapBound = mapDiv.getBoundingClientRect();
    const clickPosNorm = { x: event.clientX - mapBound.x, y: event.clientY - mapBound.y };
    const latLng = pixelToLatlng(clickPosNorm, map, maps);
    if (!latLng) {
      console.warn('invalid latLng point from', event);
      return;
    }
    const ground = selectedParking?.ground ?? DEFAULT_GROUND;
    const marker = createMarker(latLng, { ...placeToMarker[toPlace], description: { ground } }, classes, map, maps);
    marker.addListener('dragend', refreshPlaces);
    if (toPlace === 'charging') {
      marker.addListener('contextmenu', handleMarkerContextMenu(marker));
      setPlaces((places) => ({
        ...places,
        charging: [{ marker, ground }, ...(places.charging || [])],
      }));
    } else {
      setPlaces((places) => ({ ...places, [toPlace]: { marker, ground } }));
    }
    if (!groups[roles.experts]) {
      setToPlace('');
      updateChargerCtx((ctx) => ({ ...ctx, selection: undefined }));
    }
  };
  const onDeleteMarker = (marker) => {
    setPlaces((places) => ({ ...places, charging: places.charging.filter((c) => c.marker !== marker) }));
  };
  const onReplace = (contextMenuMarker, selectionObj) => {
    updateMarker(classes, maps, contextMenuMarker, selectionObj, intl);
    refreshPlaces();
    updateChargerCtx((ctx) => ({ ...ctx, selection: undefined }));
  };

  const onDeleteRoute = (route) => {
    setRoutes((routes) => routes.filter((r) => r !== route));
  };

  useEffect(() => {
    mapRef.current = map;
  }, [map]);

  /**
   * Handle expert mode
   */
  useEffect(() => {
    if (!map || !maps || !maps?.drawing || !groups[roles.experts]) {
      return;
    }

    const newDrawingManager = new maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: false,
      map,
    });
    newDrawingManager.addListener('polylinecomplete', (polyline) => {
      const lineObj = {
        line: polyline,
        type: routingMode,
        ground: selectedParking?.ground || DEFAULT_GROUND,
        from: ROUTE_ORIGIN.expert,
      };
      polyline.addListener('click', handleRouteContextMenu(lineObj));
      setRoutes((routes) => [...routes, lineObj]);
      setRoutingMode();
    });

    setDrawingManager(newDrawingManager);
    return () => {
      newDrawingManager.setMap(null);
    };
  }, [map, maps, maps?.drawing, groups[roles.experts], selectedParking?.ground, routingMode]);

  /**
   * Initialize context menu
   */
  useEffect(() => {
    if (!map || !maps) {
      return;
    }

    const popup = new (getPopupClass(map, maps))(classes, popupRef.current);
    contextMenu.current = popup;
    return () => {
      popup.setMap(null);
    };
  }, [map, maps]);

  useEffect(() => {
    if (!contextMenuRoute && !contextMenuMarker && contextMenu.current) {
      contextMenu.current.setMap(null);
    }
  }, [contextMenuRoute, contextMenuMarker]);

  /**
   * handle changes of ground level plan
   */
  useEffect(() => {
    if (!selectedParking) {
      return;
    }
    const ground = selectedParking.ground;
    if (places.divisional) {
      if (places.divisional.ground === ground) {
        places.divisional.marker.setOpacity(1);
      } else {
        places.divisional.marker.setOpacity(0.35);
      }
    }
    if (places.connection) {
      if (places.connection.ground === ground) {
        places.connection.marker.setOpacity(1);
      } else {
        places.connection.marker.setOpacity(0.35);
      }
    }
    for (const chargingPoint of places.charging || []) {
      if (chargingPoint.ground === ground) {
        chargingPoint.marker.setMap(map);
      } else {
        chargingPoint.marker.setMap(null);
      }
    }
    for (const route of routes) {
      if (route.ground === ground) {
        route.line.setMap(map);
      } else {
        route.line.setMap(null);
      }
    }
  }, [map, selectedParking?.ground]);

  /**
   * Handle click event logic (for placing charging point)
   */
  useEffect(() => {
    if (
      !map ||
      contextMenuMarker ||
      toPlace === '' ||
      (toPlace === 'divisional' && !!places.divisional) ||
      (toPlace === 'connection' && !!places.connection)
    ) {
      return;
    }
    const onClickOnMap = ({ latLng, domEvent }) => {
      const ground = selectedParking?.ground ?? DEFAULT_GROUND;
      const marker = createMarker(latLng, { ...placeToMarker[toPlace], description: { ground } }, classes, map, maps);
      marker.addListener('dragend', refreshPlaces);
      if (toPlace === 'charging') {
        marker.addListener('contextmenu', handleMarkerContextMenu(marker));
        setPlaces((places) => ({
          ...places,
          charging: [{ marker, ground }, ...(places.charging || [])],
        }));
      } else {
        setPlaces((places) => ({
          ...places,
          [toPlace]: { marker, ground },
        }));
      }
      if (!groups[roles.experts]) {
        setToPlace('');
        updateChargerCtx((ctx) => ({ ...ctx, selection: undefined }));
      }
    };
    const listener = maps.event.addDomListener(map, 'click', onClickOnMap);
    return () => {
      if (listener) {
        maps.event.removeListener(listener);
      }
    };
  }, [maps, map, toPlace, intl, placeToMarker, contextMenuMarker]);

  /**
   * Generate payload
   */
  useEffect(() => {
    computePayloadRef.current = () => {
      const chargingSolutions = chargingSolutionCodes.reduce((acc, cur) => ({ ...acc, [cur]: [] }), {});
      if (!isOutside) {
        const computeDistanceBetween = maps.geometry.spherical.computeDistanceBetween;
        for (const markerObj of places.charging || []) {
          chargingSolutions[markerObj.marker.code].push({
            description: markerObj.marker.description,
            coordinates: computePixelPosition(parkingBounds, markerObj, parkingImages, computeDistanceBetween),
          });
        }
        return convertStateToChange({
          address: quote.address,
          center: center,
          zoom: map.getZoom(),
          armoireDeDistribution: {
            description: places.divisional.marker.description,
            coordinates: computePixelPosition(parkingBounds, places.divisional, parkingImages, computeDistanceBetween),
          },
          pointDeLivraison: {
            description: places.connection.marker.description,
            coordinates: computePixelPosition(parkingBounds, places.connection, parkingImages, computeDistanceBetween),
          },
          options: [],
          isOutside,
          chargingSolutions,
          expertPath: convertInsideRouteToPayload(parkingBounds, parkingImages, computeDistanceBetween, routes, maps),
          props: {
            parkingBB:
              parkingBBajusted.current &&
              Object.entries(parkingBBajusted.current).reduce(
                (acc, [key, value]) => ({
                  ...acc,
                  [key]: convertParkingBBtoPixelPos(parkingBounds, parkingImages, computeDistanceBetween, value),
                }),
                {}
              ),
            grounds: Object.keys(parkingBounds),
          },
        });
      }
      for (const { marker } of places.charging || []) {
        chargingSolutions[marker.code].push({
          description: marker.description ?? {},
          coordinates: latLngToArray(marker.getPosition()),
        });
      }
      return convertStateToChange({
        address: quote.address,
        center: map.getCenter(),
        zoom: map.getZoom(),
        armoireDeDistribution: {
          description: places.divisional.marker.description,
          coordinates: latLngToArray(places.divisional.marker.getPosition()),
        },
        pointDeLivraison: {
          description: places.connection.marker.description,
          coordinates: latLngToArray(places.connection.marker.getPosition()),
        },
        options: [],
        isOutside,
        chargingSolutions,
        expertPath: convertRouteToPayload(routes, maps),
      });
    };
  }, [isOutside, map, places, quote.address, parkingImages, parkingBounds, maps, routes]);

  /**
   * compute parking image bounds
   */
  useEffect(() => {
    if (!map || !maps || isOutside || !maps.geometry) {
      return;
    }
    const res = {};
    for (const { file, ground } of parkingImages) {
      const { imgHeight, imgWidth } = file;
      let height;
      let width;
      const heightMax = 0.002;
      const widthMax = 0.003;
      const mapHeight = maps.geometry.spherical.computeDistanceBetween(
        { ...center, lat: center.lat - widthMax },
        { ...center, lat: center.lat + widthMax }
      );
      const mapWidth = maps.geometry.spherical.computeDistanceBetween(
        { ...center, lng: center.lng - widthMax },
        { ...center, lng: center.lng + widthMax }
      );
      if (imgHeight > imgWidth) {
        const ratio = (imgWidth / imgHeight) * (mapHeight / mapWidth);
        height = heightMax;
        width = height * ratio;
      } else {
        const ratio = (imgHeight / imgWidth) * (mapWidth / mapHeight);
        width = widthMax;
        height = width * ratio;
      }
      const imageBounds = {
        north: center.lat + height,
        south: center.lat - height,
        east: center.lng + width,
        west: center.lng - width,
      };
      const y = maps.geometry.spherical.computeDistanceBetween(
        { lat: imageBounds.north, lng: imageBounds.east },
        { lat: imageBounds.south, lng: imageBounds.east }
      );
      const x = maps.geometry.spherical.computeDistanceBetween(
        { lat: imageBounds.north, lng: imageBounds.west },
        { lat: imageBounds.north, lng: imageBounds.east }
      );
      res[ground] = { bounds: imageBounds, sizeRef: { x, y } };
    }
    setParkingBounds(res);
  }, [center.lat, center.lng, map, maps?.geometry, isOutside, parkingImages]);

  /**
   * place parking image overlay when inside, react to ground level. Fetch bounding box
   */
  useEffect(() => {
    if (!map || !maps || isOutside || !parkingBounds || !selectedParking || !Object.keys(parkingBounds).length) {
      return;
    }
    const { file, ground, key } = selectedParking;
    const overlay = new maps.GroundOverlay(file.img, parkingBounds[ground]?.bounds, { clickable: false });
    overlay.setMap(map);

    if (!parkingBB?.[ground]) {
      API.post(
        `elexent-ai-inside`,
        {
          body: JSON.stringify({
            key,
            bucket: endpoint.s3Bucket,
          }),
        },
        accessToken
      )
        .then((value) => setParkingBB((parkingBB) => ({ ...parkingBB, [ground]: value?.result?.bounding_boxes })))
        .catch((e) => console.log(e));
    }

    return () => {
      overlay.setMap(null);
    };
  }, [map, maps, isOutside, parkingBounds, selectedParking]);

  /**
   * replace charging icon with number for installation option
   */
  useEffect(() => {
    if (!afterInit) {
      return;
    }
    const chargingPoints = chargingSolutionCodes.reduce(
      (acc, code) => [...acc, ...(quote[code] || []).map((chargingPoint) => ({ code, chargingPoint }))],
      []
    );
    chargingPoints.forEach((chargingPointWithCode, i) => {
      const markerObj = (places.charging || []).find((markerObj) => {
        const m = markerObj.marker;
        return (
          m.code === chargingPointWithCode.code &&
          (isOutside
            ? areLatLngEquals(m.position, chargingPointWithCode.chargingPoint.coordinates)
            : areLatLngEqualsInside(
                computePixelPosition(
                  parkingBounds,
                  markerObj,
                  parkingImages,
                  maps.geometry.spherical.computeDistanceBetween
                ),
                chargingPointWithCode.chargingPoint.coordinates
              ))
        );
      });
      if (!markerObj) {
        console.error('no corresponding marker found', chargingPointWithCode, places.charging);
        return;
      }
      const iconColor = isChargeOrange({ codeProduct: chargingPointWithCode.code }) ? secondaryColor : primaryColor;
      const marker = markerObj.marker;
      marker.setTitle(`${chargingPointWithCode.code} n°${i + 1}`);
      marker.terminalIndex = i + 1;
      if (step !== 1) {
        marker.setIcon({
          url: isChargeOrange({ codeProduct: chargingPointWithCode.code }) ? wallboxLocSrc : wallboxLocWhiteSrc,
          scaledSize: new maps.Size(50, 50),
          labelOrigin: new maps.Point(25, 60),
        });
        marker.setLabel({
          text: `${intl.formatMessage({
            id: 'chargingSolution' + chargingPointWithCode.code,
            defaultMessage: chargingPointWithCode.code,
          })} n°${i + 1}`,
          color: 'white',
          fontFamily: 'Graphie',
          fontSize: '16px',
          fontWeight: '700',
          className: classes.markerLabel,
        });
      } else {
        marker.setIcon(markerImage(maps, iconColor));
        marker.setLabel({
          text: '' + (i + 1),
          color: 'black',
          fontFamily: 'Graphie',
          fontSize: '17px',
        });
      }
    });
  }, [step, afterInit]);

  /**
   * Update backend's places position
   */
  useEffect(() => {
    if (!places.divisional || !places.connection || !places.charging || !places.charging.length || skipEval.current) {
      skipEval.current = false;
      return;
    }
    if (!onChange || !computePayloadRef.current) {
      return;
    }
    onChange(computePayloadRef.current());
  }, [places]);

  /**
   * Enable charging positioning
   */
  useEffect(() => {
    if (places.divisional && places.connection) {
      updateChargerCtx((ctx) => ({ ...ctx, isActive: true }));
    }
  }, [places.divisional, places.connection]);

  /**
   * Place known location outside (charging point, home)
   */
  useEffect(() => {
    if (!map || !maps || !isOutside) {
      return;
    }
    const savedPdl = quote.pdl && rawPdcToInternalPdc(quote.pdl);
    const savedArmoire = quote.armoire && rawPdcToInternalPdc(quote.armoire);

    const savedSolutions = chargingSolutionCodes
      .map((code) => ({
        code,
        solutions: (quote[code] || []).map(rawPdcToInternalPdc),
      }))
      .filter((perCode) => perCode.solutions.length > 0);
    setPlaces((places) => {
      places.divisional && places.divisional.marker.setMap(null);
      places.connection && places.connection.marker.setMap(null);
      places.charging && places.charging.forEach(({ marker }) => marker.setMap(null));
      let divisionalMarker;
      let connectionMarker;
      if (savedArmoire) {
        skipEval.current = true;
        divisionalMarker = createMarker(
          new maps.LatLng(savedArmoire.coordinates ?? center),
          { ...divisionalMarkerInfo, description: savedArmoire?.description },
          classes,
          map,
          maps
        );
        divisionalMarker.addListener('dragend', refreshPlaces);
      }
      if (savedPdl) {
        skipEval.current = true;
        connectionMarker = createMarker(
          new maps.LatLng(savedPdl.coordinates),
          { ...connectionMarkerInfo, description: savedPdl?.description },
          classes,
          map,
          maps
        );
        connectionMarker.addListener('dragend', refreshPlaces);
      }
      const chargingMarkers = savedSolutions.reduce(
        (acc, { code, solutions }) => [
          ...acc,
          ...solutions.map(({ description, coordinates }) => {
            const marker = createMarker(
              new maps.LatLng(coordinates),
              {
                description,
                code,
                icon: isChargeOrange({ codeProduct: code }) ? wallboxLocSrc : wallboxLocWhiteSrc,
                label: intl.formatMessage({ id: 'chargingSolution' + code, defaultMessage: code }),
              },
              classes,
              map,
              maps
            );
            marker.addListener('dragend', refreshPlaces);
            marker.addListener('contextmenu', handleMarkerContextMenu(marker));
            return { marker, ground: description.ground };
          }),
        ],
        []
      );
      return {
        ...(divisionalMarker && { divisional: { marker: divisionalMarker, ground: savedArmoire?.description.ground } }),
        ...(connectionMarker && { connection: { marker: connectionMarker, ground: savedPdl?.description.ground } }),
        ...(chargingMarkers.length > 0 && { charging: chargingMarkers }),
      };
    });
    setAfterInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, maps]);

  /**
   * Place known location inside (charging point, home)
   */
  useEffect(() => {
    if (!map || !maps || isOutside || !parkingBounds || !parkingImages?.length || !Object.keys(parkingBounds).length) {
      return;
    }
    const savedPdl = rawPdcToInternalInsidePdc(quote.pdl ?? []);
    const savedArmoire = rawPdcToInternalInsidePdc(quote.armoire ?? []);

    const savedSolutions = chargingSolutionCodes
      .map((code) => ({
        code,
        solutions: (quote[code] || []).map(rawPdcToInternalInsidePdc),
      }))
      .filter((perCode) => perCode.solutions.length > 0);
    setPlaces((places) => {
      const currentGround = selectedParking?.ground ?? DEFAULT_GROUND;
      places.divisional && places.divisional.marker.setMap(null);
      places.connection && places.connection.marker.setMap(null);
      places.charging && places.charging.forEach(({ marker }) => marker.setMap(null));
      let divisionalMarker;
      let connectionMarker;
      if (savedArmoire.coordinates.length) {
        skipEval.current = true;
        divisionalMarker = createMarker(
          new maps.LatLng(
            computeGPSPosition(
              parkingBounds,
              currentGround,
              { position: savedArmoire.coordinates },
              parkingImages,
              maps.geometry.spherical.interpolate
            ) ?? center
          ),
          { ...divisionalMarkerInfo, description: savedArmoire.description },
          classes,
          map,
          maps
        );
        if (currentGround != savedArmoire?.description?.ground) {
          divisionalMarker.setOpacity(0.35);
        }
        divisionalMarker.addListener('dragend', refreshPlaces);
      }
      if (savedPdl.coordinates.length) {
        skipEval.current = true;
        connectionMarker = createMarker(
          new maps.LatLng(
            computeGPSPosition(
              parkingBounds,
              currentGround,
              { position: savedPdl.coordinates },
              parkingImages,
              maps.geometry.spherical.interpolate
            )
          ),
          { ...connectionMarkerInfo, description: savedPdl.description },
          classes,
          map,
          maps
        );
        if (currentGround != savedPdl?.description?.ground) {
          connectionMarker.setOpacity(0.35);
        }
        connectionMarker.addListener('dragend', refreshPlaces);
      }
      const chargingMarkers = savedSolutions.reduce(
        (acc, { code, solutions }) => [
          ...acc,
          ...solutions.map(({ description, coordinates }) => {
            const marker = createMarker(
              new maps.LatLng(
                computeGPSPosition(
                  parkingBounds,
                  currentGround,
                  { position: coordinates },
                  parkingImages,
                  maps.geometry.spherical.interpolate
                )
              ),
              {
                description,
                code,
                icon: isChargeOrange({ codeProduct: code }) ? wallboxLocSrc : wallboxLocWhiteSrc,
                label: intl.formatMessage({ id: 'chargingSolution' + code, defaultMessage: code }),
              },
              classes,
              currentGround == description?.ground ? map : undefined,
              maps
            );
            marker.addListener('dragend', refreshPlaces);
            marker.addListener('contextmenu', handleMarkerContextMenu(marker));
            return { marker, ground: description.ground };
          }),
        ],
        []
      );
      return {
        ...(divisionalMarker && { divisional: { marker: divisionalMarker, ground: savedArmoire.description.ground } }),
        ...(connectionMarker && { connection: { marker: connectionMarker, ground: savedPdl.description.ground } }),
        ...(chargingMarkers.length > 0 && { charging: chargingMarkers }),
      };
    });
    setAfterInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, parkingBounds, parkingImages, maps]);

  /**
   * Display paths outside
   */
  useEffect(() => {
    if (
      !map ||
      !quote.paths ||
      !isOutside ||
      quote.paths.length === 0 /* || (selectedFile && selectedFile.ground !== DEFAULT_GROUND)*/
      //userGroup.experts // experts don't use AI route - AI route isn't computed when expert route exists
    ) {
      return;
    }
    for (const route of routes) {
      route.line.setMap(null);
    }
    const polylines = quote.paths.map((path) => {
      const pathType = path.type ?? (isOutside ? ROUTING_MODE_BURIED : ROUTING_MODE_AERIAL);
      return {
        line: new maps.Polyline({
          path: path.coordinates.map(arrayToLatLngFast),
          map,
          strokeColor: ROUTING_COLORS[pathType],
          strokeWeight: 8,
        }),
        type: pathType,
        ground: path.ground ?? selectedParking?.ground ?? DEFAULT_GROUND,
        from: path.type != null ? ROUTE_ORIGIN.expert : ROUTE_ORIGIN.AI, // currently, AI doesn't specify path type.
      };
    });
    if (groups[roles.experts]) {
      for (const polyline of polylines) {
        polyline.line.addListener('click', handleRouteContextMenu(polyline));
      }
    }
    setRoutes(polylines);

    return () => {
      for (const route of routes) {
        route.line.setMap(null);
      }
      setRoutes([]);
    };
  }, [maps, map, quote.paths, /* selectedFile?.ground, */ groups[roles.experts]]);

  /**
   * Display paths inside
   */
  useEffect(() => {
    if (
      !map ||
      !quote.paths ||
      quote.paths.length === 0 ||
      isOutside ||
      !parkingImages?.length ||
      !parkingBounds ||
      !Object.keys(parkingBounds).length /* || (selectedFile && selectedFile.ground !== DEFAULT_GROUND)*/
      // userGroup.experts // experts don't use AI route - AI route isn't computed when expert route exists
    ) {
      return;
    }
    for (const route of routes) {
      route.line.setMap(null);
    }
    const polylines = quote.paths.map((path) => {
      const pathType = path.type ?? (isOutside ? ROUTING_MODE_BURIED : ROUTING_MODE_AERIAL);
      const ground = path.ground ?? selectedParking?.ground;
      return {
        line: new maps.Polyline({
          path: path.coordinates.map((position) =>
            computeGPSPosition(parkingBounds, ground, { position }, parkingImages, maps.geometry.spherical.interpolate)
          ),
          map: selectedParking?.ground == ground ? map : undefined,
          strokeColor: ROUTING_COLORS[pathType],
          strokeWeight: 8,
        }),
        type: pathType,
        ground: ground ?? DEFAULT_GROUND,
        from: path.type != null ? ROUTE_ORIGIN.expert : ROUTE_ORIGIN.AI, // currently, AI doesn't specify path type.
      };
    });
    if (groups[roles.experts]) {
      for (const polyline of polylines) {
        polyline.line.addListener('click', handleRouteContextMenu(polyline));
      }
    }
    setRoutes(polylines);

    return () => {
      for (const route of routes) {
        route.line.setMap(null);
      }
      setRoutes([]);
    };
  }, [maps, map, parkingBounds, parkingImages, quote.paths, /* selectedFile?.ground, */ groups[roles.experts]]);

  /**
   * handle charging selection
   */
  useEffect(() => {
    setToPlace(selectionObj ? 'charging' : '');
  }, [selectionObj]);

  const placeButtonCB = (_event, place) => {
    if (place !== 'charging') {
      const position = places[place]?.marker?.getPosition();
      if (position) {
        map && map.panTo(position);
        setToPlace('');
        return;
      }
    }
    setToPlace(place);
  };
  const placeButtonFromDragCB = (place) => (e) => {
    const canvas = document.querySelector('#dnd-canvas');
    if (!canvas) {
      console.warn('no canvas found in DOM');
      setToPlace(place);
      return;
    }
    canvas.width = 44;
    canvas.height = 60;
    const ctx = canvas?.getContext('2d');
    ctx &&
      ctx.drawImage(
        place === 'divisional' ? imgTabdiv : place === 'connection' ? imgConnect : imgCharger,
        0,
        0,
        canvas.width,
        canvas.height
      );
    e.dataTransfer.setDragImage(canvas, 22, 60);
    setToPlace(place);
  };
  const handleSendForEstimate = () => {
    if (!computeInitialQuote) {
      return;
    }
    // if (!isOutside) {
    //   computeInitialQuote({ ...computePayloadRef.current(), img: selectedParking.file.img });
    //   return;
    // }
    computeInitialQuote(computePayloadRef.current());
  };
  const handleValidate = () => {
    handleSendForEstimate();
    updateChargerCtx((ctx) => ({ ...ctx, step: 1 }));
  };

  useEffect(() => {
    stepRef.current = step;
  }, [step]);

  /**
   * Handle contextmenu replace
   */
  useEffect(() => {
    if (!isReplacing || !selectionObj) {
      return;
    }
    onReplace(contextMenuMarker, selectionObj);
    setIsReplacing(false);
    setContextMenuMarker(undefined);
  }, [selectionObj, isReplacing]);

  const handleApiLoaded = ({ map, maps }) => {
    map.setCenter(center || googleMaps.defaultState.center);
    if (!isOutside) {
      map.mapTypes.set('empty', new EmptyMapType(new maps.Size(256, 256)));
    }
    map.addListener('click', () => {
      setContextMenuMarker(undefined);
      setRouteContextMenu(undefined);
      setIsReplacing(false);
    });
    setMapObj({ map, maps });
    customControlRef.current && customControlRef.current.attach && customControlRef.current.attach({ map, maps });
  };

  const handleZoom = (value) => {
    setZoom(value);
  };

  const handleRouteContextMenu = useCallback(
    (polyline) => (e) => {
      if (stepRef.current !== 0) {
        return;
      }
      setRouteContextMenu(polyline);
      const path = polyline.line.getPath();
      const position = path.getAt(Math.floor(path.getLength() / 2));

      contextMenu.current.setPosition(position, mapRef.current);
    },
    []
  );

  const handleMarkerContextMenu = useCallback(
    (marker) => (e) => {
      if (stepRef.current !== 0) {
        return;
      }
      setContextMenuMarker(marker);
      contextMenu.current.setPosition(marker.getPosition(), mapRef.current);
    },
    []
  );

  /**
   * handle new routing mode
   */
  useEffect(() => {
    if (!drawingManager) {
      if (routingMode) {
        console.error('drawingManager not initialised. maps.drawing:', maps?.drawing);
      }
      return;
    }
    if (routingMode == null) {
      drawingManager.setOptions({ drawingMode: null });
      return;
    }
    updateChargerCtx((ctx) => ({ ...ctx, selection: undefined }));
    drawingManager.setOptions({
      drawingMode: 'polyline',
      polylineOptions: {
        strokeColor: ROUTING_COLORS[routingMode ?? (isOutside ? ROUTING_MODE_BURIED : ROUTING_MODE_AERIAL)],
        strokeWeight: 8,
      },
    });
  }, [drawingManager, routingMode]);

  const onNewRoutingMode = (_event, newRoutingMode) => {
    setRoutingMode(newRoutingMode);
  };

  const customControls = useMemo(() => {
    const controls = [];
    if (step === 0) {
      controls.push({
        position: ControlPosition.RIGHT_CENTER,
        render: (_props) => (
          <PlaceController
            placeConnection={!!places.connection}
            placeDivisional={!!places.divisional}
            {...{ placeButtonCB, placeButtonFromDragCB, toPlace }}
            handleMarkerContextMenu={handleMarkerContextMenu}
          />
        ),
      });
      if (computeInitialQuote) {
        controls.push({
          position: ControlPosition.RIGHT_BOTTOM,
          render: (_props) => <ValidateController handleValidate={handleValidate} />,
        });
      }
    }
    if (!isOutside) {
      controls.push({
        position: ControlPosition.TOP_LEFT,
        render: (_props) => <LayerController />,
      });
    }
    if (groups[roles.experts] && step === 0) {
      controls.push({
        position: ControlPosition.TOP_CENTER,
        render: (_props) => <RoutingController {...{ routingMode, onNewRoutingMode }} />,
      });
    }
    return controls;
  }, [
    computeInitialQuote,
    step,
    toPlace,
    isOutside,
    drawingManager,
    handleMarkerContextMenu,
    groups[roles.experts],
    routingMode,
  ]);

  const handleDelete = () => {
    if (!contextMenuMarker) {
      return;
    }
    contextMenuMarker.setMap(null);
    onDeleteMarker(contextMenuMarker);
    setContextMenuMarker(undefined);
  };
  const handleReplace = () => {
    if (groups[roles.experts]) {
      if (!selectionObj) {
        return;
      }
      updateMarker(classes, maps, contextMenuMarker, selectionObj, intl);
      refreshPlaces();
      setContextMenuMarker(undefined);
    } else {
      setIsReplacing(true);
    }
  };
  const handleRouteDelete = () => {
    if (!contextMenuRoute) {
      return;
    }
    contextMenuRoute.line.setMap(null);
    onDeleteRoute(contextMenuRoute);
    setRouteContextMenu(undefined);
  };
  const handleRouteReplace = (type) => () => {
    if (!contextMenuRoute) {
      return;
    }
    contextMenuRoute.type = type;
    contextMenuRoute.line.setOptions({ strokeWeight: 8, strokeColor: ROUTING_COLORS[type] });
    contextMenuRoute.from = ROUTE_ORIGIN.expert;
    setRoutes((routes) => [...routes]);
    setRouteContextMenu(undefined);
  };

  return (
    <div
      onDrop={onDrop}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      style={{ flexGrow: 1 }}
    >
      <GoogleMapReact
        resetBoundsOnResize
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={center || googleMaps.defaultState.center}
        zoom={zoom}
        center={center}
        bootstrapURLKeys={{ key: googleMaps.key, libraries: ['places', 'geometry', 'drawing'] }}
        onGoogleApiLoaded={handleApiLoaded}
        options={() => createMapOptions(isOutside)}
        onZoomAnimationEnd={(zoom) => handleZoom(zoom)}
      >
        <GMapCustomControl ref={customControlRef} controls={customControls} />
      </GoogleMapReact>
      <div ref={popupRef}>
        <ul style={{ display: contextMenuMarker ? 'block' : 'none' }} className={classes.contextMenu}>
          <li onClick={handleReplace}>
            {isReplacing ? (
              <FormattedMessage id="quoteContextMenuReplacing" defaultMessage="select a charging solution..." />
            ) : groups[roles.experts] ? (
              <FormattedMessage id="quoteContextMenuReplaceExpert" defaultMessage="replace by selection" />
            ) : (
              <FormattedMessage id="quoteContextMenuReplace" defaultMessage="replace" />
            )}
          </li>
          <li className={classes.itemDanger} onClick={handleDelete}>
            <FormattedMessage id="quoteContextMenuDelete" defaultMessage="delete" />
          </li>
        </ul>
        <ul style={{ display: contextMenuRoute ? 'block' : 'none' }} className={classes.contextMenu}>
          {generateMenuRouteItems(contextMenuRoute?.type, handleRouteReplace)}
          <li className={classes.itemDanger} onClick={handleRouteDelete}>
            <FormattedMessage id="quoteContextMenuDelete" defaultMessage="delete" />
          </li>
        </ul>
      </div>
    </div>
  );
}

HomeMap = forwardRef(HomeMap);

const generateMenuRouteItems = (routingMode, handleRouteReplace) => {
  const allItems = [
    {
      key: ROUTING_MODE_AERIAL,
      elem: (
        <li key={ROUTING_MODE_AERIAL} onClick={handleRouteReplace(ROUTING_MODE_AERIAL)}>
          <FormattedMessage id="quoteContextMenurRouteReplaceAerial" defaultMessage="Change to aerial" />
        </li>
      ),
    },
    {
      key: ROUTING_MODE_EXISTING_BURIED,
      elem: (
        <li key={ROUTING_MODE_EXISTING_BURIED} onClick={handleRouteReplace(ROUTING_MODE_EXISTING_BURIED)}>
          <FormattedMessage
            id="quoteContextMenurRouteReplaceExistingBuried"
            defaultMessage="Change to existing trunk"
          />
        </li>
      ),
    },
    {
      key: ROUTING_MODE_BURIED,
      elem: (
        <li key={ROUTING_MODE_BURIED} onClick={handleRouteReplace(ROUTING_MODE_BURIED)}>
          <FormattedMessage id="quoteContextMenurRouteReplaceBuried" defaultMessage="Change to buried" />
        </li>
      ),
    },
  ];
  return allItems.filter((i) => i.key !== routingMode).map((i) => i.elem);
};
export { HomeMap };
export default HomeMap;
