import { API, fetchApiAuth } from 'network/useRenaultApi';
import { useAuth } from 'oidc-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { roles, userGroups } from 'roles';
import { LoadingIndicator } from './common';
import { IS_AUTH_ENABLED } from './constants';
import jwt from 'jwt-decode';

export const AuthContext = React.createContext({
  attributes: '',
  groups: [],
  appToken: '',
  accessToken: '',
  me: undefined,
  hasAccessToUsers: false,
});

const setUserGroups = (allUserGroups, userGroups) => {
  const groups = allUserGroups.reduce((acc, group) => {
    acc[group] = userGroups.some((role) => role === group);
    return acc;
  }, {});
  if (userGroups.some((role) => role === roles.countryadmins)) {
    groups[roles.experts] = true;
  }
  return groups;
};

export function AuthProvider({ children }) {
  const history = useHistory();

  const [attributes, setAttributes] = useState();
  const [groups, setGroups] = useState();

  const appToken = localStorage.getItem('eltoToken');
  const [accessToken, setAccessToken] = useState(() => appToken);
  const [me, setMe] = useState({
    loading: undefined,
    value: undefined,
    error: undefined,
  });

  let auth = undefined;

  if (IS_AUTH_ENABLED && !appToken) {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      auth = useAuth();
    } catch (e) {
      //<AuthProvider> not used when accessToken is already defined
      console.log(e);
    }
  }

  useEffect(() => {
    if (!appToken) {
      return;
    }
    (async () => {
      try {
        const result = await fetchApiAuth(
          'v1/auth',
          {
            method: 'GET',
          },
          appToken
        );
        if (result.errors) {
          localStorage.removeItem('eltoToken');
          throw new Error(result.errors);
          // window.location.reload();
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [appToken, history]);

  useEffect(() => {
    (async () => {
      if (accessToken) {
        let meValue;
        try {
          meValue = await API.get('clients/whoami', {}, accessToken);
          if (meValue.errors) {
            throw new Error(meValue.errors);
          }
        } catch (e) {
          meValue = e;
        }
        setMe({
          loading: typeof meValue === 'undefined',
          value: meValue instanceof Error ? undefined : meValue,
          error: meValue instanceof Error ? meValue : undefined,
        });
      }
    })();
  }, [accessToken]);

  useEffect(() => {
    if (!auth) {
      return;
    }
    console.log('set access token', auth);
    setAccessToken(auth.userData?.access_token);
    setAttributes(auth.userData);
  }, [auth]);

  useEffect(() => {
    if (!me.value) {
      return;
    }
    setGroups({ none: !me.value.role, ...setUserGroups(userGroups, [me.value.role]) });
  }, [me.value]);

  const checkAccessToken = () => {
    // if (!auth && jwt(accessToken)?.exp < Date.now() + 3600000 * 2) {
    //   //only for ELTO TOKEN, for RENAULT its ok :)
    //   localStorage.removeItem('eltoToken');
    //   history.push({
    //     pathname: '/',
    //   });
    //   console.log('reload');
    //   window.location.reload();
    // }
    return accessToken;
  };

  if (me.error) {
    throw me.error;
    // return <ErrorAlert error={me.error} />;
  }
  if (!groups || !accessToken || !me.value) {
    return <LoadingIndicator />;
  }

  return (
    <AuthContext.Provider
      value={{
        attributes,
        groups,
        appToken,
        accessToken: checkAccessToken(),
        me: me.value,
        signOut: auth ? auth.signOut : () => {},
        hasAccessToUsers: groups ? groups[roles.administrators] || groups[roles.sales] || groups[roles.experts] : false,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('No auth context. Use AuthProvider.');
  }

  return context;
}
