import { makeStyles } from '@material-ui/core';
import AdminHomePage from 'administrators/AdminHomePage';
import { useAuthContext } from 'auth';
import { ClientHomePage } from 'clients';
import { fetchStart, useAppDispatch } from 'data-state';
import ExpertAdministrationPage from 'experts/ExpertAdministrationPage';
import ExpertHomePage from 'experts/ExpertHomePage';
import { QuotesRoutes } from 'quotes';
import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Container, Spinner } from 'reactstrap';
import { roles } from 'roles';
import MyClientsPage from 'sales/MyClientsPage';
import SalesHomePage from 'sales/SalesHomePage';
import { TestPage } from 'Test';
import { AdministrationPage } from './administrators';
import { Navbar, NoGroup, NotFoundPage } from './navigation';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    paddingTop: 64,
  },
  contentAppLauncher: {
    flexGrow: 1,
    paddingTop: 64,
  },
}));

export function Layout() {
  const { groups, accessToken } = useAuthContext();
  const { pathname } = useLocation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchStart(accessToken));
  }, [dispatch, accessToken]);

  return (
    <Suspense
      fallback={
        <Container className="d-flex justify-content-center">
          <Spinner color="contained" />
        </Container>
      }
    >
      <Navbar />

      <main style={{ height: '100%' }} className={pathname !== '/' ? classes.content : undefined}>
        <Switch>
          {groups.none && <Route component={NoGroup} />}
          {groups[roles.clients] && <Route exact path="/" component={ClientHomePage} />}
          {groups[roles.sales] && <Route exact path="/" component={SalesHomePage} />}
          {groups[roles.experts] && <Route exact path="/" component={ExpertHomePage} />}
          {groups[roles.administrators] && <Route exact path="/" component={AdminHomePage} />}
          {groups[roles.administrators] && <Route path="/administration" component={AdministrationPage} />}
          {groups[roles.experts] && <Route path="/administration" component={ExpertAdministrationPage} />}
          {(groups[roles.administrators] || groups[roles.sales] || groups[roles.experts]) && (
            <Route path="/clients" component={MyClientsPage} />
          )}
          <Route path="/quotes" component={QuotesRoutes} />
          <Route path="/estimates" component={QuotesRoutes} />
          <Route path="/test" component={TestPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </main>
    </Suspense>
  );
}
