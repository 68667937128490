import { createSelector, createSlice } from '@reduxjs/toolkit';
import { setupExistingInside } from 'data-state/quote/quote.slice';
import { surveyMock } from '../../mock';

export const SURVEY_FEATURE_KEY = 'survey';

export const initialSurveyState = {
  ...surveyMock,
  stepper: {
    currentStep: 0,
    previousStep: [],
    action: null, //'inside',
  },
};

export const surveySlice = createSlice({
  name: SURVEY_FEATURE_KEY,
  initialState: initialSurveyState,
  reducers: {
    updateAllSurvey: (state, action) => {
      state.fields = [...action.payload];
    },
    jumpStep: (state, action) => {
      state.stepper.previousStep = [...state.stepper.previousStep, state.stepper.currentStep];
      state.stepper.currentStep = action.payload;
    },
    changeStepAction: (state, action) => {
      state.stepper.action = action.payload;
    },
    reset: (state) => {
      state.stepper.currentStep = 0;
      state.stepper.previousStep = [];
      state.stepper.action = null;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(setupExistingInside, (state) => {
      state.stepper.action = 'inside';
    }),
});

export const surveyReducer = surveySlice.reducer;

export const surveyActions = surveySlice.actions;

const getSurveyState = (s) => s[SURVEY_FEATURE_KEY];

export const selectIsOutside = createSelector(getSurveyState, (s) => s.stepper.action?.includes('outside') ?? true);
export const selectCurrentStep = createSelector(getSurveyState, (s) => s.stepper.currentStep);
export const selectPreviousSteps = createSelector(getSurveyState, (s) => s.stepper.previousStep);
export const selectFields = createSelector(getSurveyState, (s) => s.fields);
