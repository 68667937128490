import { createSelector, createSlice } from '@reduxjs/toolkit';
import { supportedCountries } from 'i18n';

export const ESTIMATE_FEATURE_KEY = 'estimate';

const QxColumnName = ['createdAt', 'title', 'client', 'address', 'quoteStatus'];

export const initialEstimateState = {
  filter: {},
  columns: QxColumnName.map((e) => ({ name: e, displayed: true })),
  page: 0,
  parameters: {
    country: [...supportedCountries],
    status: ['creating', 'initial', 'finalized'],
  },
};

export const estimateSlice = createSlice({
  name: ESTIMATE_FEATURE_KEY,
  initialState: initialEstimateState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setColumns: (state, action) => {
      state.columns = action.payload.filter((t) => QxColumnName.includes(t.name));
    },
    setQuery: (state, action) => {
      state.filter.query = action.payload;
    },
  },
});

export const estimateReducer = estimateSlice.reducer;

export const estimateActions = estimateSlice.actions;

const getEstimateState = (s) => s[ESTIMATE_FEATURE_KEY];

export const selectCurrentEstimate = createSelector(getEstimateState, (s) => s);

export const selectEstimatePage = createSelector(getEstimateState, (s) => s.page);

export const selectCurrentParameters = createSelector(getEstimateState, (s) => s.parameters);

export const selectSearchParams = createSelector(getEstimateState, (s) => s.filter);

export const selectColumns = createSelector(getEstimateState, (s) => s.columns);

export const selectFilters = createSelector(getEstimateState, (s) => {
  const { date, status, country } = s.filter;
  return {
    date,
    status,
    country,
  };
});
