import { Grid, Button, TextField, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { Typography, Card } from 'ui';
import { authActions, selectAuthType, useAppDispatch, useAppSelector } from 'data-state';
import { fetchApiAuth } from 'network/useRenaultApi';
import { useToast } from 'common';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import translate from '../i18n/locales/loginMock.json';
import { getFormattedLangage, useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  alignForm: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  forgetPassRoot: {
    margin: theme.spacing(0.5),
  },
  forgetPass: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 500,
  },
  ctaButtonRoot: {
    textAlign: 'center',
  },
  rootMenuItem: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '120%',
    overflow: 'visible',
  },
}));

export function Login(props) {
  const classes = useStyles();
  const history = useHistory();

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const lang = getFormattedLangage(navigator.language) || intl?.country?.toLowerCase();
  const translations = translate?.[lang];

  const appToken = localStorage.getItem('eltoToken');
  const [signIn, setSignIn] = useState(false);

  const [login, setLogin] = useState('');
  const [secret, setSecret] = useState('');
  const toast = useToast();

  const [forgetPass, setForgetPass] = useState(false);

  useEffect(() => {
    if (signIn && appToken) {
      history.push({
        pathname: '/',
      });
    }
  }, [signIn]);

  const handleSignIn = async () => {
    const results = await fetchApiAuth(`v1/login`, {
      method: 'POST',
      body: JSON.stringify({
        login,
        secret,
      }),
    });
    if (results.errors) {
      const messageError = results.errors.map((e) => e.errorMessage || '').join(' , ');
      toast(
        <Toast>
          <ToastHeader icon="error" toggle={() => toast(null)} />
          <ToastBody>{messageError}</ToastBody>
        </Toast>
      );
      return;
    }
    localStorage.setItem('eltoToken', results.token);
    localStorage.removeItem('authRenault');
    dispatch(authActions.setToken(results.token));
    setSignIn(true);
  };

  const handleForgetPass = async () => {
    try {
      const results = await fetchApiAuth(`v1/forgot`, {
        method: 'POST',
        body: JSON.stringify({
          login,
        }),
      });
      if (results.errors) {
        const messageError = results.errors.map((e) => e.errorMessage || '').join(' , ');
        toast(
          <Toast>
            <ToastHeader icon="error" toggle={() => toast(null)} />
            <ToastBody>{messageError}</ToastBody>
          </Toast>
        );
        return;
      }
      if (results.renew) {
        handleForgetPassView();
      }
    } catch (e) {
      toast(
        <Toast>
          <ToastHeader icon="error" toggle={() => toast(null)} />
          <ToastBody>{e.toString()}</ToastBody>
        </Toast>
      );
    }
  };
  const onValidate = async () => {
    forgetPass ? await handleForgetPass() : await handleSignIn();
  };

  const handleForgetPassView = () => setForgetPass((fp) => !fp);

  const handleRenaultLogin = () => {
    localStorage.setItem('authRenault', true);
    localStorage.removeItem('eltoToken');
    dispatch(authActions.setAuthType('renault'));
    history.push({
      pathname: '/',
    });
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid item xs={6}>
          <Card
            title={
              forgetPass
                ? translations?.forgetPassTitle || 'Mot de passe MPS oublié'
                : translations?.loginTitle || 'Connexion à votre espace MPS'
            }
          >
            <Grid container justifyContent={'center'} alignItems={'center'}>
              <Grid item xs={12} className={classes.alignForm}>
                <TextField
                  onChange={(e) => setLogin(e.target.value)}
                  value={login}
                  name="login"
                  label={translations?.login || 'Votre identifiant'}
                  style={{ width: '50%' }}
                />
              </Grid>
              {!forgetPass && (
                <Grid item xs={12} className={classes.alignForm}>
                  <TextField
                    value={secret}
                    name="secret"
                    onChange={(e) => setSecret(e.target.value)}
                    type="password"
                    label={translations?.password || 'Votre mot de passe'}
                    style={{ width: '50%' }}
                  />
                </Grid>
              )}
              <div className={classes.forgetPassRoot}>
                <Typography
                  variant="bodyXS"
                  onClick={handleForgetPassView}
                  className={classes.forgetPass}
                  component="p"
                >
                  {forgetPass
                    ? translations?.loginBack || 'Revenir à la page de connexion'
                    : translations?.forgetPass || 'Mot de passe oublié ?'}
                </Typography>
              </div>
              <Grid item xs={12} className={classes.ctaButtonRoot}>
                <Button variant="contained" onClick={onValidate} disabled={forgetPass ? !login : !(login && secret)}>
                  {forgetPass
                    ? translations?.passReinitialize || 'Reinitialiser mon mot de passe'
                    : translations?.validate || 'Valider'}
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="labelL"
              align="center"
              style={{ marginTop: '15px', cursor: 'pointer' }}
              onClick={handleRenaultLogin}
            >
              {translations?.connexionType || 'me connecter avec le SSO Renault Group'}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
